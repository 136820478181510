<template>
  <div id="search">
    <div class="company_name">
      <img src="../../assets/yige_logo.png" alt="" />
    </div>
    <div class="search_ok" v-if="show">
      <div class="search_oks">
        <h3>您的产品查验结果</h3>
        <span><img src="../..//assets/zhengpin.png" alt="" /></span>
        <h4>正品</h4>
      </div>
      <span
        ><img src="../../assets/fangweihao.png" alt="" />防伪码{{
          checkCode
        }}对应的产品是正品</span
      >
      <span
        ><img src="../../assets/fangwenliang.png" alt="" />此防伪码已被查询过{{
          num
        }}次,如非本人操作,请谨防假冒</span
      >
    </div>
    <div class="search_error" v-else>
      <h3>您的产品查验结果</h3>
      <h4>没有查询到您输入的防伪码，请校验防伪码重新查询</h4>
    </div>
    <p>
      本网站所载的各种信息和数据真实有效，如因其他原因造成网站查询系统奔溃或无法正常使用，请重新查询或等系统恢复后查询。
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
      num: 0,
      checkCode: "",
    };
  },
  created() {
    console.log(this.$route.query.show);
    this.show = this.$route.query.show;
    this.checkCode = this.$route.query.checkCode;
    this.num = this.$route.query.num;
  },
};
</script>
<style scope lang="scss">
#search {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 34px;
    padding: 0 60px;
    margin-top: 76px;
  }
  .company_name {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 116px;
    border-bottom: 2px solid #ededed;
    margin-bottom: 112px;
    img {
      width: 200px;
      height: 54px;
    }
  }
  .search_error {
    padding: 0 40px;
    width: 670px;
    height: 670px;
    background: #ffffff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-top: 82px;
      margin-bottom: 168px;
    }
    h4 {
      font-size: 28px;
      width: 370px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 40px;
      text-align: center;
    }
  }
  .search_ok {
    padding: 0 40px;
    width: 670px;
    height: 670px;
    background: #ffffff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    .search_oks {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 86px;
      h3 {
        font-size: 40px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      span {
        width: 116px;
        height: 116px;
        margin-top: 90px;
        margin-bottom: 44px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        font-size: 40px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
        margin-bottom: 100px;
      }
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 22px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
    }
  }
}
</style>
